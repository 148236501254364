
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    status: Boolean,
    name: String,
    description: String,
  },
  data() {
    return {
      extended: false,
    };
  },
});
