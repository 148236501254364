
import { defineComponent } from "vue";

import Test from "@/components/TestCase.vue";
import type { TestCase } from "@/types";

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
    tests: {
      type: Array,
      required: true,
    },
    success: {
      type: Boolean,
      required: true,
    },
    lastUpdated: {
      type: Date,
      required: false,
      default: undefined,
    },
  },

  methods: {
    getDesc(test: TestCase) {
      if (test.status_description !== "") {
        return test.status_description;
      } else {
        return "No description defined";
      }
    },
  },
  components: {
    Test,
  },
});
