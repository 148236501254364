import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-730bea34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "testCaseTitle" }
const _hoisted_2 = {
  key: 0,
  class: "test-description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["testCase", {
      ok: _ctx.status === true,
      fail: _ctx.status === false,
      skipped: _ctx.description === 'Skipped.',
    }]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.extended = !_ctx.extended), ["prevent"]))
  }, [
    _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.name), 1),
    (_ctx.extended)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true)
  ], 2))
}